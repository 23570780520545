import React, { useEffect, useState } from 'react'
import abimg from '../../images/about/private_about_eventfabrik.jpg'
import abimg2 from '../../images/about/private_about_dresscode.jpg'
import abimg3 from '../../images/about/private_arborea.jpg'
import abimg4 from '../../images/about/private_gifts.jpg'
import { HashLink as Link } from 'react-router-hash-link'
import { keyboard } from '@testing-library/user-event/dist/keyboard'
import Cookies from 'universal-cookie'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const About = (props) => {
    const [content, setContent] = useState([]);
    const [invitationContent, setInvitationContent] = useState('');
    const [hotelContent, setHotelContent] = useState('');
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const cookie = new Cookies();
        const code = cookie.get('code');

        if(code == undefined || code == null) {
            window.location = '/login';
        }
        if(loaded == false) {
            setLoaded(true);

            fetch('https://hermann-hambrecht-wedding.de/static/media/private_info.json', {
                crossDomain: true,
                credentials:'include'
            }).then((response) => {
                return response.json();
            }).then((json) => {
                const invitation = json.filter((o) => {
                    return o.Key == 'Invitation'
                });
                setInvitationContent(invitation[0].Content);

                const hotel = json.filter((o) => {
                    return o.Key == 'Hotel'
                });
                setHotelContent(hotel[0].Content);
            })
        }
       
    });

    return (
        <section className="wpo-about-section section-padding">
            <div className="container">
                <div className="wpo-about-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-item">
                                <div className="wpo-about-img">
                                    <img src={abimg} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-text">
                                <h2>Let's Par ty!</h2>
                                <h4>Einladung</h4>
                                <p dangerouslySetInnerHTML={ { __html: invitationContent } }></p>
                                <Link onClick={ClickHandler} to="/home#feedback" className="theme-btn">Rückmeldung geben!</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wpo-about-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12 order-lg-1 order-2">
                            <div className="wpo-about-text">
                                <h2>Dresscode</h2>
                                <h4>Smart Casual</h4>
                                <p>Im Vordergrund steht, dass ihr euch wohl fühlt und einen langen Abend mit uns durchsteht. Packt also die Tanzschuhe ein! Dennoch wollen wir 
                                    diesen besonderen Tag in festlicher Gardrobe feiern. Als Orientierung könnt ihr euch an den Dresscode <strong>Smart Casual</strong> halten. 
                                </p>
                                {/* <Link onClick={ClickHandler} to="/contact" className="theme-btn">CONTACT US</Link> */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 order-lg-2 order-1">
                            <div className="wpo-about-item">
                                <div className="wpo-about-img">
                                    <img src={abimg2} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="wpo-about-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-item">
                                <div className="wpo-about-img">
                                    <img src={abimg3} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-text">
                                <h2>Hotel</h2>
                                <h4>Kontingent</h4>
                                <div dangerouslySetInnerHTML={ { __html: hotelContent } }></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wpo-about-wrap" style={{marginTop:'-80px'}}>
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-12 order-lg-1 order-2">
                            <div className="wpo-about-text">
                                <h2>Geschenke</h2>
                                <h4>Wer nicht ohne kommen möchte...</h4>
                                <p>Unser größtes Geschenk ist es, mit Euch zu feiern! Wenn ihr uns trotzdem etwas schenken möchtet, freuen wir uns über einen Beitrag zu unseren Flitterwochen.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 order-lg-2 order-1">
                            <div className="wpo-about-item">
                                <div className="wpo-about-img">
                                    <img src={abimg4} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;