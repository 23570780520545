import React, { Fragment, useState, useRef } from 'react';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import { Link }  from "react-router-dom";
import './style.css';
import {toast} from "react-toastify";
import { Hidden } from '@material-ui/core';
import { getImageSize } from 'react-image-size';

const menus = [
    {
        id: 1,
        title: 'Home',
        link: '/home'
    },

{
    id: 2,
        title: 'Zeitplan',
        link: '/timeline',
    },
{
    id: 4,
        title: 'Gallerie',
        link: '/gallery',
    },

    {
        id: 7,
        title: 'Upload',
        link: '/upload',
        
    }
    
    
]


const MobileMenu = () => {

    const [openId, setOpenId] = useState(0);
    const [menuActive, setMenuState] = useState(false);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    var inputFile = useRef<HTMLInputElement | null>(null);

    const onUpload = (e) => {
        inputFile.click();
    }

    const onFileChange = async (e) => {
        const apiKey = localStorage.getItem('apiKey');
        const file = inputFile.files[0];

        const url = URL.createObjectURL(file);
        const { width, height } = await getImageSize(url);

        fetch("https://www.konerowschwarzwedding.de/api/getuploadurl", {
            method: 'POST',
            headers: {
                'x-api-key': apiKey,
                'Content-Type':'application/json'
            },
            body: JSON.stringify({ name: file.name, contentType: file.type, width: width, height: height })
        }).then(response => response.json())
        .then((data) => {
            const json = JSON.parse(data.body);

            if(json.success === true) {
                const url = json.uploadUrl;

                fetch(url, {
                    method: 'PUT',
                    body: file
                }).then(response => {
                    console.info(response);

                    if(response.status == 200)
                        toast.success('Datei wurde erfolgreich hochgeladen.');
                }).catch((error) => {
                    toast.error('Beim Upload ist ein Fehler aufgetreten.');
                })
            } else {
                toast.error('Beim Upload ist ein Fehler aufgetreten.');
            }
        })
        .catch((error) => {
            console.error(error);
            toast.error('Beim Upload ist ein Fehler aufgetreten.');
        });
    };

    return (
        <div>
            <div className={`mobileMenu ${menuActive ? "show" : ""}`}>
                <div className="menu-close">
                    <div className="clox" onClick={() => setMenuState(!menuActive)}><i className="ti-close"></i></div>
                </div>

                <ul className="responsivemenu">
                    {menus.map((item, mn) => {
                        return (
                            <ListItem className={item.id === openId ? 'active' : null}  key={mn}>
                                {item.submenu ?
                                    <Fragment>
                                        <p onClick={() => setOpenId(item.id === openId ? 0 : item.id)}>{item.title}
                                          <i className={item.id === openId ? 'fa fa-angle-up' : 'fa fa-angle-down'}></i>
                                        </p>
                                        <Collapse in={item.id === openId} timeout="auto" unmountOnExit>
                                            <List className="subMenu">
                                                <Fragment>
                                                    {item.submenu.map((submenu, i) => {
                                                        return (
                                                            <ListItem key={i}>
                                                                <Link onClick={ClickHandler}
                                                                    to={submenu.link}>{submenu.title}</Link>
                                                            </ListItem>
                                                        )
                                                    })}
                                                </Fragment>
                                            </List>
                                        </Collapse>
                                    </Fragment>
                                    : item.link === '/upload' ? 
                                        <a onClick={onUpload} className="menu-item-has-children">Upload
                                            <input type='file' id='file' ref={(ref) => inputFile = ref} style={{display: 'none'}} onChange={onFileChange} />
                                        </a> : <Link
                                        to={item.link}>{item.title}</Link>
                                }
                            </ListItem>
                        )
                    })}
                </ul>

            </div>

            <div className="showmenu" onClick={() => setMenuState(!menuActive)}>
                <button type="button" className="navbar-toggler open-btn">
                    <span className="icon-bar first-angle"></span>
                    <span className="icon-bar middle-angle"></span>
                    <span className="icon-bar last-angle"></span>
                </button>
            </div>
        </div>
    )
}

export default MobileMenu;