import React from 'react'
import { Link } from 'react-router-dom'
import pImg from '../../images/pricing/p-shape.svg'
import SectionTitle from '../SectionTitle'


const Pricing = [
    {
        pakage: 'Bayside',
        price: '280',
        subs: 'pro Nacht',
        l1: 'Scharbeutz',
        l2: 'Entfernung: 12km',
        l3: 'Direkte Strandlage',
        l4: 'Spa',
        l5: 'Frühstück',
        link: 'https://www.bayside.de'
    },
    {
        pakage: 'aja Grömitz',
        price: '174',
        subs: 'pro Nacht',
        l1: 'Grömitz',
        l2: 'Entfernung: 14km',
        l3: 'Direkte Strandlage',
        l4: 'Spa',
        l5: 'Frühstück',
        link: 'https://www.aja.de/standorte/groemitz.html'
    },
    {
        pakage: 'Hotel Vier Jahreszeiten Lübeck',
        price: '113',
        subs: 'pro Nacht',
        l1: 'Lübeck',
        l2: 'Entfernung: 30km',
        l3: 'Stadtnähe',
        l4: 'Pool',
        l5: 'exkl. Frühstück',
        link: 'https://www.4jahreszeiten-luebeck.de/'
    },
]


const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

const PricingSection = (props) => {

    return (
        <section className={`wpo-pricing-section section-padding ${props.pvClass}`}>
            <div className="container">
                <SectionTitle subTitle={'Trinken statt fahren'} MainTitle={'Alternative Hotels'} />
                <div className="wpo-pricing-wrap">
                    <div className="row">
                        {Pricing.map((pricing, pitem) => (

                            <div className="col col-lg-4 col-md-6 col-12" key={pitem}>
                                <div className="wpo-pricing-item">
                                    <div className="wpo-pricing-top">
                                        <div className="wpo-pricing-text">
                                            <h4>{pricing.pakage}</h4>
                                            <h2>{pricing.price}€<span>/{pricing.subs}</span></h2>
                                        </div>
                                    </div>
                                    <div className="shape"><img src={pImg} alt=""/></div>
                                    <div className="wpo-pricing-bottom">
                                        <div className="wpo-pricing-bottom-text">
                                            <ul>
                                                <li>{pricing.l1}</li>
                                                <li>{pricing.l2}</li>
                                                <li>{pricing.l3}</li>
                                                <li>{pricing.l4}</li>
                                                <li>{pricing.l5}</li>
                                            </ul>
                                            <a href={pricing.link} target="_blank" className="theme-btn-s4">Buchen</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PricingSection;