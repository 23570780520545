import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import SimpleReactValidator from "simple-react-validator";
import {toast} from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {Link, useNavigate} from "react-router-dom";
import Cookies from 'universal-cookie'
import './style.scss';



const LoginPage = (props) => {

    const push = useNavigate()

    const [value, setValue] = useState({
        code: '',
        remember: true,
    });

    const changeHandler = (e) => {
        setValue({...value, [e.target.name]: e.target.value});
        validator.showMessages();
    };

    const rememberHandler = () => {
        setValue({...value, remember: !value.remember});
    };

    const [validator] = React.useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));

    React.useEffect(() => {
        const cookie = new Cookies();
        const code = cookie.get('code');

        if(code !== undefined && code !== null) {
            // Ensure we got api key
            const apiKey = localStorage.getItem('apiKey');

            if(apiKey === undefined || apiKey === null) {
                cookie.remove('code');
            } else {
                push('/home');
            }
        }
    });

    const login = () => {
        fetch(`https://konerowschwarzwedding.de/api/login`, {
            // fetch('/api/login', { 
                method: 'post',
                    headers: {'Content-Type':'application/json'},
                    body: JSON.stringify({ code: value.code })
                })
                .then(response => response.json())
                .then((data) => {
                    const json = JSON.parse(data.body);

                    if(json.success === true) {
                        const code = value.code;
                        const cookies = new Cookies();
                        cookies.set('code', json.cookie, { domain: '.konerowschwarzwedding.de', sameSite:'lax'});
                        localStorage.setItem('apiKey', json.apiKey);
                        toast.success('Du wurdest erfolgreich eingeloggt!');
                        push('/home');
                    } else {
                        toast.error('Der Einladungscode ist falsch');
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
    };

    const submitForm = (e) => {
        e.preventDefault();
        if (validator.allValid()) {
            setValue({
                code: '',
                remember: false
            });
            validator.hideMessages();

            login();
            // fetch(`https://konerowschwarzwedding.de/api/login`, {
            // // fetch('/api/login', { 
            //     method: 'post',
            //         headers: {'Content-Type':'application/json'},
            //         body: JSON.stringify({ code: value.code })
            //     })
            //     .then(response => response.json())
            //     .then((data) => {
            //         const json = JSON.parse(data.body);

            //         if(json.success === true) {
            //             const code = value.code;
            //             const cookies = new Cookies();
            //             cookies.set('code', json.cookie);
            //             localStorage.setItem('apiKey', json.apiKey);
            //             toast.success('Du wurdest erfolgreich eingeloggt!');
            //             push('/home');
            //         } else {
            //             toast.error('Der Einladungscode ist falsch');
            //         }
            //     })
            //     .catch((error) => {
            //         console.error(error);
            //     });
        } else {
            validator.showMessages();
            toast.error('Empty field is not allowed!');
        }
    };

    return (
        <Grid className="loginWrapper">
            <Grid className="loginForm">
                <h2>Login</h2>
                <p>Bitte gebt den Code eurer Einladung ein!</p>
                <form onSubmit={submitForm}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Code"
                                value={value.code}
                                variant="outlined"
                                name="code"
                                label="Zugangscode"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('code', value.code, 'required|code')}
                        </Grid>
                        {/* <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Password"
                                value={value.password}
                                variant="outlined"
                                name="password"
                                type="password"
                                label="Password"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('password', value.password, 'required')}
                        </Grid> */}
                        <Grid item xs={12}>
                            {/* <Grid className="formAction">
                                <FormControlLabel
                                    control={<Checkbox checked={value.remember} onChange={rememberHandler}/>}
                                    label="Remember Me"
                                />
                                <Link to="/forgot-password">Forgot Password?</Link>
                            </Grid> */}
                            <Grid className="formFooter">
                                <Button fullWidth className="cBtnTheme" type="submit">Login</Button>
                            </Grid>
                            {/* <Grid className="loginWithSocial">
                                <Button className="facebook"><i className="fa fa-facebook"></i></Button>
                                <Button className="twitter"><i className="fa fa-twitter"></i></Button>
                                <Button className="linkedin"><i className="fa fa-linkedin"></i></Button>
                            </Grid>
                            <p className="noteHelp">Don't have an account? <Link to="/register">Create free account</Link>
                            </p> */}
                        </Grid>
                    </Grid>
                </form>
                <div className="shape-img">
                    <i className="fi flaticon-honeycomb"></i>
                </div>
            </Grid>
        </Grid>
    )
};

export default LoginPage;