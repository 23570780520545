import React, { useState } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {toast} from "react-toastify";


const RSVPFrom = () => {

    const [forms, setForms] = useState({
        Name: '',
        Teilnahme: '',
        Erwachsene: '',
        Kinder: '',
        Unvertraglichkeit: '',
    });

    const [busy, setBusy] = useState({
        loading: false,
        sentSuccessfully: false
    });

    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage',
        messages: {
            name: 'Bitte gebe deinen Namen ein.',
            Teilnahme: 'Bitte angeben, ob du/ ihr kommen könnt.',
            Erwachsene: 'Bitte gebt die Anzahl der Erwachsenen an.'
        }
    }));
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = e => {
        e.preventDefault();
        if (validator.allValid()) {
            validator.hideMessages();

            const apiKey = localStorage.getItem('apiKey');
            setBusy({ loading: true });
            fetch(`https://konerowschwarzwedding.de/api/invitationresponse`, {
            // fetch('/api/invitationresponse', { 
                method: 'post',
                    headers: {'Content-Type':'application/json', 'x-api-key':apiKey},
                    body: JSON.stringify(forms)
                })
                .then(response => response.json())
                .then((data) => {
                    const json = JSON.parse(data.body);

                    if(json.success === true) {
                        if(forms.Teilnahme === 'Ja') {
                            toast.success('Wir haben dein Feedback erhalten. Wir freuen uns, dass ihr diesen Tag mit uns feiert!');
                        } else {
                            toast.success('Wir haben dein Feedback erhalten und bedauern, dass ihr nicht kommen könnt!');
                        }
                    } else {
                        toast.error('Leider ist etwas schief gelaufen. Wir haben Dein Feedback nicht erhalten!');
                    }
                    setBusy({ loading: false, sentSuccessfully: json.success });
                    setForms({
                        Name: '',
                        Teilnahme: '',
                        Erwachsene: '',
                        Kinder: '',
                        Unvertraglichkeit: '',
                    })
                })
                .catch((error) => {
                    console.error(error);
                    toast.error('Leider ist etwas schief gelaufen. Wir haben Dein Feedback nicht erhalten!');
                    setBusy({ loading: false, sentSuccessfully: false });
                });

            
        } else {
            validator.showMessages();
        }
    };

    const [startDate, setStartDate] = useState(new Date());

    return (
        <form onSubmit={(e) => submitHandler(e)} className="contact-validation-active" >
            <div className="row">
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <input
                            value={forms.Name}
                            type="text"
                            name="Name"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            className="form-control"
                            placeholder="Name" />
                        {validator.message('name', forms.Name, 'required')}
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <select
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            value={forms.Teilnahme}
                            type="text"
                            className="form-control"
                            name="Teilnahme">
                            <option>Teilnahme</option>
                            <option>Ja</option>
                            <option>Nein</option>
                        </select>
                        {validator.message('Teilnahme', forms.Teilnahme, 'required')}
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <select
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            value={forms.Erwachsene}
                            type="text"
                            className="form-control"
                            name="Erwachsene">
                            <option>Anzahl Erwachsene</option>
                            <option>0</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                        </select>
                        {validator.message('Erwachsene', forms.Erwachsene, 'required')}
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <select
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            value={forms.Kinder}
                            type="text"
                            className="form-control"
                            name="Kinder">
                            <option>Anzahl Kinder</option>
                            <option>0</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                        </select>
                        {validator.message('Kinder', forms.Kinder, 'optional')}
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <input
                            value={forms.name}
                            type="text"
                            name="Unverträglichkeit"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            className="form-control"
                            placeholder="Unverträglichkeiten" />
                    </div>
                </div>
            </div>
            <div className="submit-area">
                { (!busy.loading && !busy.sentSuccessfully) && <button type="submit" className="theme-btn-s3">Feedback senden</button> }
            </div>
        </form >
    )
}

export default RSVPFrom;