import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/logo.svg'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const Footer = (props) => {
    const [contactContent, setContactContent] = useState('');
    const [witnessesContent1, setWitnessesContent1] = useState('');
    const [witnessesContent2, setWitnessesContent2] = useState('');
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if(loaded == false) {
            setLoaded(true);

            fetch('https://konerowschwarzwedding.de/static/media/private_info.json', {
                crossDomain: true,
                credentials:'include'
            }).then((response) => {
                return response.json();
            }).then((json) => {
                const contact = json.filter((o) => {
                    return o.Key == 'Contact'
                });
                setContactContent(contact[0].Content);

                const witnesses = json.filter((o) => {
                    return o.Key == 'Witnesses1'
                });
                setWitnessesContent1(witnesses[0].Content);

                const witnesses2 = json.filter((o) => {
                    return o.Key == 'Witnesses2'
                });
                setWitnessesContent2(witnesses2[0].Content);
            })
        }
       
    });

    return (
        <div className="wpo-site-footer text-center">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="footer-image">
                            <Link className="logo" to="/"><img src={Logo} alt=""/></Link>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6 col-md-6 col-sm-12">
                        <div className="footer-image">
                            <h3>Kontakt</h3>
                            <div dangerouslySetInnerHTML={ { __html: contactContent } }></div>
                        </div>
                    </div>
                    <div className="col-6 col-md-6 col-sm-12">
                        <div className="footer-image">
                            <h3>Trauzeugen</h3>
                            <div dangerouslySetInnerHTML={ { __html: witnessesContent1 } } style={{fontSize:'13px', color:'#7b7b7b', marginTop:'30px'}}></div>
                            <div dangerouslySetInnerHTML={ { __html: witnessesContent2 } } style={{fontSize:'13px', color:'#7b7b7b', marginTop:'10px'}}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;